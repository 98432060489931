import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import { EverydayFeature, SideBottomImage, TeamBackImg } from "../../../images/index";

import Button from "../../../components/Button";
import MotoList from "../../../containers/MotoList";
import CheckItOut from "../../../containers/CheckItOut";
import LookingForInspiration from "../../../containers/LookingForInspiration";
import ImageFeatureBlock from "../../../components/ImageFeatureBlock";
import FeatureList from "../../../containers/FeatureList";
import { featureData } from "../../../constants/feature-data";
import { motoLists } from "../../../constants/moto-list";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "solutions",
    link: "/solutions",
  },
  {
    text: "everyday performance",
    link: "",
  },
];

const EverydayPerfomance = ({ data }) => {
  const everydayPerformance = data.everydayPerformance.nodes[0];
  const lookingForInspiration = data.lookingForInspiration.nodes;
  const everydayFooterBanner = data.everydayFooterBanner.nodes[0];
  return (
    <Layout>
      <Seo title="Solutions-Everyday Performance" />
      <Banner
        bgImg={everydayPerformance.desktopImage.gatsbyImageData}
        mobImg={everydayPerformance.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-12 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold sm:text-normal xl:text-[2.5rem] 2xl:text-5xl">
                <strong>powering enterprise and</strong>
                <br />
                <strong>team performance with</strong>
              </h2>
            }
            subTitle={
              <p className=" sm:text-base md:-mt-4 text-[#000000] font-normal ml-4 mr-8 sm:mx-0 lg:text-3xl xl:text-[2.5rem] text-3xl xl:w-full 2xl:text-5xl">
                goal alignment and behavior changing insights and nudges
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="get started today"
            style={{ backgroundColor: "#000", color: "#ffda00" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 1rem" }} />
      </Container>
      <ImageFeatureBlock
        sideImg={EverydayFeature}
        sideBottomImage={SideBottomImage}
        heading="driving enterprise and distributed
        team performance"
        subTitle="The future of work is already here – with distributed teams working from traditional ‘owned’ physical offices, shared workplaces, and virtual offices from home or remote locations of their choice. It’s critical that your teams have:"
        featureDataList={[
          "a clear line-of-sight on individual, organization, and team goals",
          "seamless collaboration and communication channels that power performance",
          "ability to instantly recognize contributions and celebrates milestones",
          "actionable information on priority tasks and ability to identify which people own, support or share in contributing to the goals set",
        ]}
      />
      <div className="bg-black">
        <MotoList featureList={motoLists} />
      </div>
      <Container>
        <FeatureList featureData={featureData} />
      </Container>
      <CheckItOut
        bgImg={TeamBackImg}
        heading="for small teams working
      on game-changing
      innovations"
        description="explore our pre-configured editions that are designed to help you accomplish targeted objectives."
      />
      <LookingForInspiration
        title="looking for inspiration?"
        description="see how some of our customers are getting tomorrow-ready"
        storyData={[
          {
            image: lookingForInspiration[0].storyOne.gatsbyImageData,
            text: "Enhanced business decision-making, on-the-go sales team tracking for over 100 branches and sub-branches",
            fullStoryLink:
              "https://entomo.co/success-stories/democratized-insights-gamification-for-accelerated-productivity-for-24000-plus-employees/",
          },
          {
            image: lookingForInspiration[0].storyTwo.gatsbyImageData,
            text: "Transforming productivity and performance for over 60,000 employees for a large government agency – MEA",
            fullStoryLink:
              "https://entomo.co/success-stories/transforming-employee-engagement-for-over-60-business-units-for-this-fastest-growing-conglomerate/",
          },
        ]}
      />
      <Banner
        height="auto"
        bgImg={everydayFooterBanner.desktopImage.gatsbyImageData}
        mobImg={everydayFooterBanner.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[17%] 2xl:left-28 sm:w-1/2 md:left-12 lg:left-20 left-1.5 md:top-48 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 -mt-20 w-fit mx-8 sm:mx-0 md:-mt-40 xl:-mt-16 lg:top-52 font-extrabold">
          <BannerText
            title={
              <h2 className="mt-4 text-white text-3xl xl:text-4xl">
                <strong>start with the basics</strong>
              </h2>
            }
            subTitle={
              <p className="text-white text-lg sm:w-72 font-normal  xl:text-2xl">
                connect with our team to get an in-depth understanding of all the features.
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="book a demo"
            style={{ backgroundColor: "#FFDA00", color: "#000000" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
    </Layout>
  );
};
EverydayPerfomance.propTypes = {
  data: PropTypes.object,
};
export default EverydayPerfomance;
export const query = graphql`
  query {
    everydayPerformance: allContentfulBannerContent(
      filter: { heading: { eq: "Solutions everyday performance page" } }
    ) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    lookingForInspiration: allContentfulLookingForInspiration(
      filter: { heading: { eq: "Solutions everyday performance page" } }
    ) {
      nodes {
        storyOne {
          gatsbyImageData(formats: WEBP)
        }
        storyTwo {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    everydayFooterBanner: allContentfulBannerContent(
      filter: { heading: { eq: "Solutions everyday performance footer banner" } }
    ) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
  }
`;
