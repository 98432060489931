export const motoLists = [
  {
    title: "transparency & visibility",
    featureList: [
      "drive purpose and create synergy between your organization’s growth strategy, operational plan, and individual goals and employees.",
      "acreate a goal culture.",
      "align your workforce.",
    ],
  },
  {
    title: "accountability & productivity",
    featureList: [
      "provide insights to empower people with the information they need to perform better.",
      "make it easier to facilitate conversations between individuals and across teams – and ensure that they are more contextual than ever.",
    ],
  },
  {
    title: "collaboration & co-creation	",
    featureList: [
      "shift away from conventional approaches to annual reviews and embrace a more meaningful, continuous review and feedback model.",
      "help employees accomplish more by clearly defining a growth plan and setting easily achievable short-term and long-term goals.",
    ],
  },
];
