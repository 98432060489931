import { FeatureFive, FeatureFour, FeatureOne, FeatureSix, FeatureThree, FeatureTwo } from "../images";
export const featureData = [
  {
    title: "power continuous performance and a goal-driven culture",
    featureList: [
      "set a framework to achieve desired goals — design a goal engine that works for you regardless of which KPI processes your organization uses. Start creating value within 4-6 weeks.",
      "set clear objectives to enhance agility and focus in the organization.",
      "set up custom performance programs that empower employees to align individual goals with team and organization goals with our proprietary automated alignment engine.",
      "deploy tailor-made strategy roadmaps inspired by the balanced scorecard methodology.",
    ],
    image: FeatureOne,
  },
  {
    title: "drive collaboration and engagement",
    featureList: [
      "enhance work transparency with visibility to organization’s and team objectives – available on-demand.",
      "enable frequent conversations to better track individual needs, performance, updates and progress.",
      "drive a comprehensive, peer-to-peer and 360 feedback culture.",
      "help your employees plan their tasks & meet their goals with recommended actions and nudges, as Things to Know and Things to Do.",
    ],
    image: FeatureTwo,
    reversed: "true",
  },
  {
    title: "create performance behaviors",
    featureList: [
      "power high performance with the right data insights – for individuals, teams and the enterprise.",
      "proactively identify and predict where employees may need additional support.",
      "enable managers to ensure the team is on track throughout the review cycle and nudge them to meet their targets.",
      "give hyper-personalized contextual insights and nudges with our easy-to-use Employee Hub, which makes for more organised employees – and better performance outcomes.",
    ],
    image: FeatureThree,
  },
  {
    title: "recognition & rewards",
    featureList: [
      "drive purpose and create synergy between your organization’s growth strategy, operational plan, and individual goals and employees.",
      "create a goal culture.",
      "align your workforce.",
    ],
    image: FeatureFour,
    reversed: "true",
  },
  {
    title: "transparency & visibility",
    featureList: [
      "gamify performance and productivity with contests and individual scorecards.",
      "send timely kudos and recognition to retain top talent.",
    ],
    image: FeatureFive,
  },
  {
    title: "empower with insights",
    featureList: [
      "deep dive into contextual insights to understand employee motivations and performance triggers.",
      "democratize easy-to-understand performance reports so that employees can take self-corrective measures.",
      "share insights across the organization to improve managerial decision-making.",
      "build a proactive workforce that acts on insights and not just intuition.",
    ],
    image: FeatureSix,
    reversed: "true",
  },
];
